<template>
  <div>
    <a href="javascript:;" class="login_type_2" @click="loginWithFacebook">
      <img class="facebook" src="../assets/img/facebook.png" />
    </a>
  </div>
</template>
<script>
// import FB from "facebook-js-sdk";
export default {
  name: "",
  mixins: [],
  components: {},
  props: {
    fb_appid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/zh_TW/sdk.js#xfbml=1&version=v3.3";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  },
  mounted() {},
  methods: {
    loginWithFacebook() {
      FB.init({
        appId: this.fb_appid,
        cookie: true,
        xfbml: true,
        version: "v11.0",
      });
      FB.login((response) => {
        let res = JSON.parse(JSON.stringify(response));
        if (res.authResponse?.accessToken) {
          let hash = window.location.pathname;
          if (hash.includes("/login")) {
            this.Login(res.authResponse);
          } else {
            this.guestLogin(res.authResponse);
          }
        } else {
          this.$Msg(res.status);
        }
      });
    },
    Login(authResponse) {
      let webUUID = this.$store.state.webUUID || Adjust.getWebUUID();
      let params = {
        game_id: localStorage.getItem("game_id"),
        device_id: webUUID,
        third_platform: 1,
        third_account: authResponse.userID,
        third_account_token: authResponse.accessToken,
      };
      this.$http.post("/apis/h5/thirdLogin", params).then((res) => {
        if (res.code === 200) {
          switch (Number(params.game_id)) {
            case 14:
              Adjust.trackEvent({
                eventToken: "3s39it",
              });
              break;
            case 15:
              Adjust.trackEvent({
                eventToken: "mjjqh8",
              });
              break;
            default:
              break;
          }
          if (res.data.isReg !== "0") {
            switch (Number(params.game_id)) {
              case 14:
                Adjust.trackEvent({
                  eventToken: "dz32s3",
                });
                Adjust.trackEvent({
                  eventToken: "ilhflt",
                });
                break;
              case 15:
                Adjust.trackEvent({
                  eventToken: "l049mw",
                });
                Adjust.trackEvent({
                  eventToken: "5taqik",
                });
                break;
              default:
                break;
            }
          }
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("userid", res.data.userid);
          localStorage.setItem("iframeURL", res.data.login_url);
          localStorage.removeItem("guestInfo");
          this.$store.state.token = res.data.token;
          this.$store.state.iframeURL = res.data.login_url;
          // this.$router.push("/main");
          this.$router.push("/main/my");
          this.$store.state.sdkShow = false;
          this.$store.commit("openSdk", false);
        } else {
          this.$Msg(res.msg);
        }
      });
    },
    guestLogin(authResponse) {
      let webUUID = this.$store.state.webUUID || Adjust.getWebUUID();
      let userid = localStorage.getItem("userid");
      let token = localStorage.getItem("token");
      let params = {
        game_id: localStorage.getItem("game_id"),
        device_id: webUUID,
        third_platform: 1,
        third_account: authResponse.userID,
        third_account_token: authResponse.accessToken,
        userid,
        token,
      };
      this.$http.post("/apis/h5/thirdUpgrade", params).then((res) => {
        if (res.code === 200) {
          // switch (Number(params.game_id)) {
          //   case 121:
          //     Adjust.trackEvent({
          //       eventToken: "wjzkta",
          //     });
          //     break;
          //   default:
          //     break;
          // }
          // if (res.data.isReg !== "0") {
          //   switch (Number(params.game_id)) {
          //     case 121:
          //       Adjust.trackEvent({
          //         eventToken: "dibvk8",
          //       });
          //       break;
          //     default:
          //       break;
          //   }
          // }
          localStorage.removeItem("token");
          localStorage.removeItem("userid");
          localStorage.removeItem("iframeURL");
          localStorage.removeItem("guestInfo");
          this.$store.state.guest = true;
          this.$store.state.token = "";
          this.$store.state.iframeURL = "";
          this.$router.push({
            path: "/login",
            query: { game_id: localStorage.getItem("game_id") },
          });
          this.$store.commit("openSdk", false);
        } else {
          this.$Msg(res.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.facebook {
  width: 40px;
  height: 40px;
}
</style>
