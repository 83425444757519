<template>
  <div class="page">
    <Navbar :isBack="true" :title="$t('login.changePassword')" />
    <div class="main">
      <div class="cell">
        <SvgIcon
          name="email"
          class="cell_icon"
          :color="$config.primary_color"
        />
        <div class="cell_input">
          <input
            type="text"
            v-model="email"
            :placeholder="$t('login.emailPlaceholder')"
          />
        </div>
      </div>
      <div class="login_code">
        <div class="cell">
          <SvgIcon
            name="code"
            class="cell_icon"
            :color="$config.primary_color"
          />
          <div class="cell_input">
            <input
              type="text"
              v-model="code"
              :placeholder="$t('login.codePlaceholder')"
            />
          </div>
        </div>
        <div @click="getCode()" class="login_code_btn">
          {{ codeTime > 0 ? codeTime : $t("login.getCode") }}
        </div>
      </div>
      <div class="cell">
        <SvgIcon
          name="password"
          class="cell_icon"
          :color="$config.primary_color"
        />
        <div class="cell_input">
          <input
            type="text"
            v-model="password"
            :placeholder="$t('login.newPassword')"
          />
        </div>
      </div>
      <div class="cell">
        <SvgIcon
          name="password"
          class="cell_icon"
          :color="$config.primary_color"
        />
        <div class="cell_input">
          <input
            type="text"
            v-model="password2"
            :placeholder="$t('login.repeatPassword')"
          />
        </div>
      </div>
      <div class="another_way">
        <div class="way_text">{{ $t("login.otherBind") }}</div>
        <div class="way">
          <ul class="list-action">
            <li>
              <FbLogin :fb_appid="fb_appid" />
            </li>
            <li>
              <GoogleLogin :client_id="client_id" ref="GoogleLogin" />
            </li>
          </ul>
        </div>
      </div>
      <div class="submit_btn" @click="confirm()">
        {{ $t("login.changePassword") }}
      </div>
    </div>
  </div>
</template>
<script>
import FbLogin from "@/components/FbLogin.vue";
import GoogleLogin from "@/components/GoogleLogin.vue";
export default {
  data() {
    return {
      codeTime: 0,
      email: "",
      code: "",
      password: "",
      password2: "",
    };
  },
  components: { FbLogin, GoogleLogin },
  mounted() {
    setTimeout(() => {
      this.$refs.GoogleLogin.initGoogle();
    }, 150);
  },
  computed: {
    fb_appid() {
      return this.$store.state.fb_appid;
    },
    client_id() {
      return this.$store.state.client_id;
    },
  },
  methods: {
    getCode() {
      if (this.email === "") {
        this.$Msg(this.$t("login.emailCheck"));
        return;
      }
      if (this.codeTime <= 59 && this.codeTime > 0) {
        return;
      }
      let userid = localStorage.getItem("userid");
      let token = localStorage.getItem("token");
      let params = {
        game_id: this.$store.state.game_id,
        email: this.email,
        userid,
        token,
      };
      this.$http.post("/apis/h5/sendUpgradeCode", params).then((res) => {
        if (res.code === 200) {
          this.codeTime = 59;
          let codeTimeInt = setInterval(() => {
            this.codeTime = this.codeTime - 1;
            if (this.codeTime == 0) {
              clearInterval(codeTimeInt);
            }
          }, 1000);
        } else {
          this.$Msg(res.msg);
        }
      });
    },
    confirm() {
      if (this.email && this.code && this.password && this.password2) {
        let userid = localStorage.getItem("userid");
        let token = localStorage.getItem("token");
        let webUUID = this.$store.state.webUUID || Adjust.getWebUUID();
        if (this.password === this.password2) {
          let params = {
            game_id: localStorage.getItem("game_id"), //游戏id
            email: this.email, //邮箱账号
            password: this.password, //密码
            auth_code: this.code, //短信验证码
            userid,
            token,
            device_id: webUUID,
          };
          this.$http.post("/apis/h5/accountUpgrade", params).then((res) => {
            if (res.code === 200) {
              // switch (Number(params.game_id)) {
              //   case 14:
              //     Adjust.trackEvent({
              //       eventToken: "dibvk8",
              //     });
              //     break;
              //   default:
              //     break;
              // }
              localStorage.removeItem("token");
              localStorage.removeItem("userid");
              localStorage.removeItem("iframeURL");
              localStorage.removeItem("guestInfo");
              this.$store.state.guest = true;
              this.$store.state.token = "";
              this.$store.state.iframeURL = "";
              this.$router.push({
                path: "/login",
                query: { game_id: localStorage.getItem("game_id") },
              });
              this.$store.commit("openSdk", false);
              this.$Msg(this.$t("login.PasswordSuccess"));
            } else {
              this.$Msg(res.msg);
            }
          });
        } else {
          this.$Msg(this.$t("login.differencePassword"));
        }
      }
    },
  },
};
</script>
<style scoped lang='scss'>
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.8rem 0.3rem;
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  font-size: 0.25rem;
}
.cell {
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
  padding: 0 0.3rem;
  width: 100%;
  height: 0.76rem;
  background-color: #eeeeee;
  border-radius: 1rem;
  box-sizing: border-box;
}
.cell_icon {
  margin-right: 0.2rem;
  width: 0.45rem;
  height: 0.45rem;
}
.cell_input {
  flex: 1;
  overflow: hidden;
}
.cell_input input {
  width: 100%;
  height: 0.3rem;
}
.cell_input input::placeholder {
  color: #8d8d8d;
  font-size: 0.25rem;
}
.login_code {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.3rem;
  width: 100%;
}
.login_code .cell {
  flex: 1;
  margin: 0;
  width: auto;
}
.login_code_btn {
  margin-left: 0.2rem;
  width: 1.8rem;
  height: 0.76rem;
  line-height: 0.76rem;
  text-align: center;
  color: $color-primary;
  border: 1px solid $color-primary;
  border-radius: 1rem;
  box-sizing: border-box;
}
.another_way {
  color: $color-default;
  margin-bottom: 0.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  .way_text {
    margin: 0.2rem 0;
  }
}
.way {
  display: flex;
  justify-content: space-around;
  width: 70%;
  .list-action {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
      list-style-type: none;
      margin: 0 10px;

      a {
        font-size: 0.75rem;
        color: #000;
        -webkit-transition: all 0.3s linear;
        transition: all 0.3s linear;
        display: block;
        text-decoration: none !important;

        img {
          width: 1.5rem;
          height: 1.5rem;
          border: 0;
          display: block;
          margin: auto;
          margin-bottom: 0.5rem;
          -webkit-transition: all 0.3s;
          transition: all 0.3s;
        }
      }
    }
  }
  .way_phone_svg {
    position: relative;
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    background-color: $color-primary;
    overflow: hidden;
    svg {
      width: 0.5rem;
      height: 0.5rem;
      position: absolute;
      top: 0.1rem;
      left: 0.1rem;
    }
  }
  .way_fb_svg {
    position: relative;
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    background-color: #007cf2;
    overflow: hidden;
    svg {
      width: 0.6rem;
      height: 0.6rem;
      position: absolute;
      top: 0.05rem;
      left: 0.05rem;
    }
  }
}
.submit_btn {
  margin-top: 0.2rem;
  width: 90%;
  line-height: 0.7rem;
  text-align: center;
  background-color: $color-primary;
  color: #fff;
  border-radius: 1rem;
}
</style>