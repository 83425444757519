<template>
  <div class="page" @click.stop>
    <Navbar :isBack="true" :title="$t('login.register')" />
    <div class="main">
      <div class="cell">
        <SvgIcon
          name="email"
          class="cell_icon"
          :color="$config.primary_color"
        />
        <div class="cell_input">
          <input
            type="text"
            v-model="email"
            :placeholder="$t('login.emailPlaceholder')"
          />
        </div>
      </div>
      <div class="login_code">
        <div class="cell">
          <SvgIcon
            name="code"
            class="cell_icon"
            :color="$config.primary_color"
          />
          <div class="cell_input">
            <input
              type="text"
              v-model="code"
              :placeholder="$t('login.codePlaceholder')"
            />
          </div>
        </div>
        <div @click="getCode()" class="login_code_btn">
          {{ codeTime > 0 ? codeTime : $t("login.getCode") }}
        </div>
      </div>
      <div class="cell">
        <SvgIcon
          name="password"
          class="cell_icon"
          :color="$config.primary_color"
        />
        <div class="cell_input">
          <input
            type="text"
            v-model="password"
            :placeholder="$t('login.newPassword')"
          />
        </div>
      </div>
      <div class="cell">
        <SvgIcon
          name="password"
          class="cell_icon"
          :color="$config.primary_color"
        />
        <div class="cell_input">
          <input
            type="text"
            v-model="password2"
            :placeholder="$t('login.repeatPassword')"
          />
        </div>
      </div>
      <div class="submit_btn" @click="confirm()">
        {{ $t("login.register") }}
      </div>
      <!-- <div class="another_way">
        <div class="way_text">其他登录方式</div>
        <div class="way">
          <div class="way_fb_svg"><SvgIcon name="fb" color="#ffffff" /></div>
        </div>
      </div>
      <div class="read">
        <div>
          <input
            type="checkbox"
            class="checkbox"
            v-model="checkbox"
            name="myCheckbox"
          />
        </div>
        <div class="read_text">我已阅读并同意《用户协议》</div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      codeTime: 0,
      email: "",
      code: "",
      password: "",
      password2: "",
      checkbox: false,
    };
  },
  methods: {
    getCode() {
      if (this.email === "") {
        this.$Msg(this.$t("login.emailCheck"));
        return;
      }
      if (this.codeTime <= 59 && this.codeTime > 0) {
        return;
      }
      let params = {
        game_id: this.$store.state.game_id,
        email: this.email,
      };
      this.$http.post("/apis/h5/sendRegisterCode", params).then((res) => {
        if (res.code === 200) {
          this.codeTime = 59;
          let codeTimeInt = setInterval(() => {
            this.codeTime = this.codeTime - 1;
            if (this.codeTime == 0) {
              clearInterval(codeTimeInt);
            }
          }, 1000);
        } else {
          this.$Msg(res.msg);
        }
      });
    },
    confirm() {
      if (this.email && this.code && this.password && this.password2) {
        if (this.password === this.password2) {
          let webUUID = this.$store.state.webUUID || Adjust.getWebUUID();
          let params = {
            game_id: this.$store.state.game_id, //游戏id
            username: this.email, //邮箱账号
            passwd: this.password, //密码
            device_id: webUUID,
            auth_code: this.code, //短信验证码
            reg_type: 1,
          };
          this.$http.post("/apis/h5/normalReg", params).then((res) => {
            if (res.code === 200) {
              switch (Number(params.game_id)) {
                case 14:
                  Adjust.trackEvent({
                    eventToken: "1pfywu",
                  });
                  Adjust.trackEvent({
                    eventToken: "ilhflt",
                  });
                  break;
                case 15:
                  Adjust.trackEvent({
                    eventToken: "2bv3cx",
                  });
                  Adjust.trackEvent({
                    eventToken: "5taqik",
                  });
                  break;
                default:
                  break;
              }
              this.$router.push("/main/my");
              this.$Msg(this.$t("login.registerSuccess"));
            } else {
              this.$Msg(res.msg);
            }
          });
        } else {
          this.$Msg(this.$t("login.differencePassword"));
        }
      } else {
        this.$Msg(this.$t("login.noData"));
      }
    },
  },
};
</script>
<style scoped lang='scss'>
.page {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 7rem;
  width: 6rem;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.3s;
  background-color: #fff;
}
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.3rem 0.3rem;
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  font-size: 0.25rem;
}
.cell {
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
  padding: 0 0.3rem;
  width: 100%;
  height: 0.76rem;
  background-color: #eeeeee;
  border-radius: 1rem;
  box-sizing: border-box;
}
.cell_icon {
  margin-right: 0.2rem;
  width: 0.45rem;
  height: 0.45rem;
}
.cell_input {
  flex: 1;
  overflow: hidden;
}
.cell_input input {
  width: 100%;
  height: 0.3rem;
}
.cell_input input::placeholder {
  color: #8d8d8d;
  font-size: 0.25rem;
}
.login_code {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.3rem;
  width: 100%;
}
.login_code .cell {
  flex: 1;
  margin: 0;
  width: auto;
}
.login_code_btn {
  margin-left: 0.2rem;
  width: 1.8rem;
  height: 0.76rem;
  line-height: 0.76rem;
  text-align: center;
  color: $color-primary;
  border: 1px solid $color-primary;
  border-radius: 1rem;
  box-sizing: border-box;
}
.submit_btn {
  margin-top: 0.2rem;
  width: 90%;
  line-height: 0.7rem;
  text-align: center;
  background-color: $color-primary;
  color: #fff;
  border-radius: 1rem;
}
.another_way {
  color: $color-default;
  margin-bottom: 0.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  .way_text {
    margin: 0.2rem 0;
  }
}
.way {
  display: flex;
  justify-content: space-around;
  width: 50%;
  .way_phone_svg {
    position: relative;
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    background-color: $color-primary;
    overflow: hidden;
    svg {
      width: 0.5rem;
      height: 0.5rem;
      position: absolute;
      top: 0.1rem;
      left: 0.1rem;
    }
  }
  .way_fb_svg {
    position: relative;
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    background-color: #007cf2;
    overflow: hidden;
    svg {
      width: 0.6rem;
      height: 0.6rem;
      position: absolute;
      top: 0.05rem;
      left: 0.05rem;
    }
  }
}
.read {
  display: flex;
  .checkbox {
    width: 0.3rem;
    height: 0.3rem;
  }
  .read_text {
    color: $color-primary;
  }
}
</style>